<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="promotions"
        :loading="loading"
        :options.sync="options"
        :server-items-length="getPromotionsTotalCount"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
      >
        <template v-slot:top>
          <FormToolbar title="Акції">
            <template v-slot:right>
              <PromotionDialog
                :is-opened="dialog"
                :is-editing="editedIndex > -1"
                :edited-item="editedItem"
                @yes="save"
                @no="close"
              />
              <DeleteDialog
                :is-opened="dialogDelete"
                message="Акцію буде видалено"
                @yes="deletePromotionConfirm"
                @no="closeDelete"
              />
            </template>
          </FormToolbar>
        </template>
        <template v-slot:item.id="{ item }">
          <router-link
            :to="{
              name: 'PromotionCard',
              params: { id: currentCompanyId, promotionId: item.id },
            }"
            class="text-decoration-none"
          >
            {{ item.id }}
          </router-link>
        </template>
        <template v-slot:item.start_at="{ item }">{{ item.start_at | getShortDate }}</template>
        <template v-slot:item.expired_at="{ item }">{{ item.expired_at | getShortDate }}</template>
        <template v-slot:item.type="{ item }">{{ getPromType(item.type) }}</template>
        <template v-slot:item.is_available_once="{ item }">{{ item.is_available_once ? 'Разова' : 'Постійна' }} </template>
        <template v-slot:item.is_active="{ item }">
          <CircleIndicator :is-on="item.is_active" />
        </template>
        <template v-slot:item.users="{ item }">
          <div v-for="user in item.users" :key="user.id">
            <router-link
              :to="{
                name: 'UserCard',
                params: { id: currentCompanyId, userId: user.id },
              }"
              class="text-decoration-none"
            >
              {{ user.first_name }} {{ user.last_name }}
            </router-link>
          </div>
        </template>
        <template v-slot:item.value_type="{ item }">{{ item.value_type === 1 ? 'грн.' : '%' }}</template>
        <template v-slot:item.devices="{ item }">
          <div v-for="(device, index) in item.devices" :key="index">
            <router-link :to="{ name: 'DeviceCard', params: { deviceId: device.id } }" class="text-decoration-none">
              {{ device.id }}
            </router-link>
          </div>
        </template>
        <template v-slot:item.service_points="{ item }">
          <div v-for="point in item.service_points" :key="point.id">
            <router-link
              :to="{
                name: 'ServicePointCard',
                params: { id: currentCompanyId, servicePointId: point.id },
              }"
              class="text-decoration-none"
            >
              {{ point.name }}
            </router-link>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <TableActions @edit="editItem(item)" @delete="deletePromotion(item)" @view="navigateToPromotionCard(item)" />
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import convertDate from '@/mixins/convertDate'
import forceRerender from '@/mixins/forceRerender'
import CloneDeep from 'lodash/cloneDeep'
import CircleIndicator from '@/components/common/CircleIndicator'
import DeleteDialog from '@/components/dialogs/DeleteDialog'
import PromotionDialog from '@/components/marketing/promotions/PromotionDialog'
import FormToolbar from '@/components/common/forms/FormToolbar'
import TableActions from '@/components/common/forms/TableActions'
import sortUtils from '@/mixins/sortUtils'
import { PROMOTION_SCHEMA } from '@/const/apiSchemas'

export default {
  name: 'PromotionsList',
  components: {
    TableActions,
    CircleIndicator,
    FormToolbar,
    PromotionDialog,
    DeleteDialog,
  },

  mixins: [convertDate, forceRerender, sortUtils],

  data() {
    return {
      search: '',
      headers: [
        { text: 'ID', align: 'left', sortable: true, value: 'id' },
        {
          text: 'Термін дії з',
          align: 'left',
          sortable: true,
          value: 'start_at',
        },
        {
          text: 'Термін дії до',
          align: 'left',
          sortable: true,
          value: 'expired_at',
        },
        {
          text: 'Вид акції',
          align: 'left',
          sortable: false,
          value: 'is_available_once',
        },
        { text: 'Тип акції', align: 'left', sortable: true, value: 'type' },
        { text: 'Назва акції', align: 'left', sortable: true, value: 'title' },
        {
          text: 'Включено',
          align: 'center',
          sortable: false,
          value: 'is_active',
        },
        { text: 'Клієнти', align: 'left', sortable: false, value: 'users' },
        {
          text: 'Вид бонуса',
          align: 'left',
          sortable: false,
          value: 'value_type',
        },
        {
          text: 'Розмір бонуса',
          align: 'right',
          sortable: true,
          value: 'amount',
        },
        {
          text: 'Торгівельна точка',
          align: 'left',
          sortable: false,
          value: 'service_points',
        },
        { text: 'Автомат', align: 'left', sortable: false, value: 'devices' },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '125px',
          align: 'center',
        },
      ],
      options: {},
      dialog: false,
      dialogDelete: false,
      loading: false,
      editedIndex: -1,
      editedItem: {},
      clickedPromotionId: null,
    }
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete()
    },
    options: {
      async handler() {
        await this.paginateTo()
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('promotions', ['promotions', 'promotionsMeta']),
    ...mapState('dictionaries', ['companyPromotionsDictionary']),

    ...mapGetters('promotions', ['getPromotionsTotalCount']),

    currentCompanyId() {
      return this.$route.params.id
    },
  },

  methods: {
    ...mapActions('promotions', ['loadPromotions', 'createPromotion', 'updateSelectedPromotion', 'deleteSelectedPromotion']),

    async paginateTo() {
      this.loading = true
      const payload = {
        company: this.currentCompanyId,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        sort: this.sortObject,
      }
      await this.loadPromotions(payload)
      this.loading = false
    },

    getPromType(type) {
      const prom = this.companyPromotionsDictionary.find((item) => item.value === type)
      return prom?.name || null
    },

    editItem(item) {
      this.editedIndex = this.promotions.indexOf(item)
      this.editedItem = CloneDeep(item)
      this.clickedPromotionId = item.id
      this.forceRerender()
      this.dialog = true
    },

    close() {
      this.editedIndex = -1
      this.editedItem = CloneDeep(PROMOTION_SCHEMA)
      this.dialog = false
    },

    async save(payload) {
      try {
        if (this.editedIndex === -1) {
          await this.createPromotion({
            companyId: this.currentCompanyId,
            newPromotion: payload,
          })
        } else {
          await this.updateSelectedPromotion({
            companyId: this.currentCompanyId,
            id: this.clickedPromotionId,
            updatedPromotion: payload,
          })
        }
      } finally {
        this.close()
        await this.paginateTo()
      }
    },

    deletePromotion(item) {
      this.clickedPromotionId = item.id
      this.dialogDelete = true
    },

    async deletePromotionConfirm() {
      this.loading = true
      const payload = {
        companyId: this.currentCompanyId,
        id: this.clickedPromotionId,
      }
      await this.deleteSelectedPromotion(payload)
      await this.paginateTo()
      this.loading = false
      this.closeDelete()
    },

    closeDelete() {
      this.dialogDelete = false
      this.clickedPromotionId = null
      this.editedIndex = -1
    },

    navigateToPromotionCard(item) {
      this.$router.push({
        name: 'PromotionCard',
        params: {
          id: this.currentCompanyId.toString(),
          promotionId: item.id.toString(),
        },
      })
    },
  },
}
</script>
