<template>
  <div class="flex flex-row justify-space-between">
    <slot name="prepend"></slot>
    <v-tooltip v-if="!noEditButton" :open-on-focus="false" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon class="mr-2" small v-bind="attrs" @click="$emit('edit')" v-on="on"> mdi-pencil-outline</v-icon>
      </template>
      <span>Редагувати</span>
    </v-tooltip>
    <v-tooltip v-if="!noDeleteButton" :open-on-focus="false" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon class="mr-2" small v-bind="attrs" @click="$emit('delete')" v-on="on"> mdi-delete-outline </v-icon>
      </template>
      <span>Видалити</span>
    </v-tooltip>
    <v-tooltip v-if="!noViewButton" :open-on-focus="false" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon small v-bind="attrs" @click="$emit('view')" v-on="on"> mdi-eye-outline</v-icon>
      </template>
      <span>Переглянути</span>
    </v-tooltip>
    <slot name="append"></slot>
  </div>
</template>

<script>
export default {
  name: 'TableActions',

  props: {
    noEditButton: {
      type: Boolean,
      default: false,
    },

    noDeleteButton: {
      type: Boolean,
      default: false,
    },

    noViewButton: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['edit', 'delete', 'view'],
}
</script>
